import * as XLSX from "xlsx";
import React, {useState} from 'react';
import {Row, Col, Table, message, Tag, Button, Modal, Input, DatePicker, InputNumber, Radio} from 'antd';
import {AllBaseInfo, CheckAllBaseInfo, UploadOneBaseInfo, UserProfile} from "../common";
import type { ColumnsType } from 'antd/es/table';
import {BasicResponse} from "../../../Http/common";
import handleAxios from "../../../Http/request"
import BaseInfoEditor from "../../../componts/BaseInfoEditor";

/**
 * 上传文件页面
 */
export default function () {

    const [tableData, setTableData] = useState<UserProfile[]>([]);

    // 消息提醒
    const [messageApi, contextHolder] = message.useMessage();

    // 选中的行
    const [selectedRows, setSelectedRows] = useState<UserProfile[]>([]);

    // 对话框编辑新增的病人基础信息
    const [editing, setEditing] = useState<AllBaseInfo | undefined>(undefined);

    /**
     * 上传指定病例到服务器
     * @param uploadItems
     */
    const upload = async (uploadItems: UserProfile[]) => {
        // 上传的数据为空，不处理。
        if (uploadItems.length === 0) {
            return;
        }
        let ret: Promise<BasicResponse> = handleAxios.post("/api/uploadProfiles", uploadItems);
        ret.then((res) => {
            if (res.code === 0) {
                messageApi.open({
                    type: "success",
                    content: res.data
                })
            } else {
                messageApi.open({
                    type: "error",
                    content:res.data
                })
            }
        }).catch(error => {
            // 只要发生异常，就返回重定向页面，大概率是session过期了
            messageApi.error("用户信息失效，请重新登陆");
            // window.location.href="/login";
        })
    }

    /**
     * 上传某条元素到服务器
     * @param index
     */
    function uploadItem(index: number) {
        let uploadItem = tableData.find(item=> item.id === index);
        if (uploadItem === undefined) {
            return;
        } else {
            upload([uploadItem])
        }
    }

    /**
     * 批量上传到服务器
     * @param items
     */
    function batchUploadItem(items:UserProfile[]) {
        if (items.length === 0) {
            return;
        } else {
            upload(items)
        }
    }

    /**
     * 删除某条记录
     * @param index
     */
    function deleteItem(index: number) {
        let newTableData: UserProfile[] = tableData.filter((item) => item.id !== index)
        console.log("删除指定元素后的数组为", newTableData);
        setTableData(newTableData);
        messageApi.open({
            type:"success",
            content:"删除表中一条记录"
        })
    }

    /**
     * 批量删除打开的表格中的数据。
     * @param items
     */
    function batchDeleteItem(items: UserProfile[]) {
        let keysSet = new Set();
            items.forEach((tempItem) => {
                keysSet.add(tempItem.id)
            })
        let newTableData: UserProfile[] = tableData.filter(tempItem => {
            return !keysSet.has(tempItem.id)
        });
        setTableData(newTableData);
        messageApi.open({
            type:"success",
            content: "批量删除成功"
        })
    }

    // 表头的所有列
    const tableHeader: ColumnsType<UserProfile> = [
        {
            title:"序号",
            dataIndex:"id",
            key:"id",
            width: 100,
            sorter: (a, b) => a.id - b.id,
        },
        {
            title:"患者ID",
            dataIndex: "userId",
            key:"userId",
            width: 100,
            sorter: (a, b) => Number(a.userId) - Number(b.userId)
        },
        {
            title:"病案号",
            dataIndex: "profileId",
            key:"profileId",
            width: 200,
            sorter:(a, b) => Number(a.profileId) - Number(b.profileId)
        },
        {
            title:"姓名",
            dataIndex: "name",
            key:"name",
            width: 150,
        },
        {
            title:"生日",
            dataIndex: "birthday",
            key:"birthday",
            width: 150
        },
        {
            title:"性别",
            dataIndex: "gender",
            key:"gender",
            width: 120,
            onFilter: (value, record) => {
                return record.gender === value
            },
            sorter:(a, b) => a.gender.localeCompare(b.gender),
            filters: [
                {
                    text: "男",
                    value: "男",
                },
                {
                    text: "女",
                    value: "女"
                }
            ],
            render:(_, {gender}) => {
                if (gender == '男') {
                    return (
                        <Tag color="blue">
                            {gender}
                        </Tag>
                    )
                } else if (gender === '女') {
                    return (
                        <Tag color="magenta">
                            {gender}
                        </Tag>
                    )
                } else {
                    return (
                        <Tag >
                            空
                        </Tag>
                    )
                }
            }
        },
        {
            title:"备注",
            dataIndex: "ext",
            key:"ext",
            ellipsis: true,
            render: (_, {ext}) =>
            {
                // 长度大于25字符的仅展示25字符
                let ret = "";
                if (ext === undefined) {
                    ret = "空"
                } else {
                    ret = ext;
                }
                return (
                    <>
                        {ret}
                    </>
                )
            }
        },
        {
            title: "操作",
            key:"action",
            width: 200,
            render: (index, record) => {
                return (
                    <>
                        <Button
                            type="text"
                            style={{
                                color:"green"
                            }}
                            onClick={() => {
                                uploadItem(record.id)}
                            }
                        >
                            上传
                        </Button>
                        <Button
                            style={{
                                marginLeft:"5px"
                            }}
                            type="text"
                            danger
                            onClick={()=> {
                                deleteItem(record.id)
                            }}
                        >
                            删除
                        </Button>
                    </>
                )
            }
        }
    ]

    /**
     * 读取选中的excel数据
     * @param e
     */
    function importExcel(e:any) {
        var files = e.target.files;
        if (files.length === 0) {
            return;
        }
        try {
            // var name = files.name;
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target!.result;
                const wb = XLSX.read(bstr, {type: 'binary'})
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data:any[] = XLSX.utils.sheet_to_json(ws);
                let userDate:UserProfile[] = [];
                data.forEach((value, index) => {
                    // console.log(value);
                    userDate.push({
                        "id":index + 1,
                        "userId": value.患者ID,
                        "profileId":value.病案号,
                        "name":value.患者姓名,
                        "birthday":value.出生年月,
                        "gender":value.性别,
                        "ext":value.备注,
                    })
                })
                setTableData(userDate);
                messageApi.open({
                    type: 'success',
                    content: "读取excel成功！"
                })
            }
            reader.readAsBinaryString(files[0]);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: "上传文件失败！请选择合法的xls文件。"
            })
        }
    }

    /**
     * 格式化老日期
     * @param dateStr
     */
    function formatOldBrithday(dateStr:string) {
        if (dateStr === "") {
            return ""
        }

        // console.log(dateStr)
        let num = Number(dateStr);
        let millisecond = 0;//强制转化后的毫秒数
        if (num > 60) {
            millisecond = (num - 25569) * 60 * 60 * 24 * 1000;
        } else {
            millisecond = (num - 25568) * 60 * 60 * 24 * 1000;
        }
        let newDate = new Date(millisecond);
        let year = newDate.getFullYear();
        let month = newDate.getMonth() + 1 < 10 ? "0" + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
        let day = newDate.getDate() < 10 ? "0" + (newDate.getDate()) : newDate.getDate();

        let retStr = String(year) + "-" + String(month) + "-" + String(day);
        // console.log(retStr);
        return retStr;
    }

    /**
     * 读取选中的excel数据
     * @param e
     */
    function importOldExcel(e:any) {
        var files = e.target.files;
        if (files.length === 0) {
            return;
        }
        try {
            // var name = files.name;
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target!.result;
                const wb = XLSX.read(bstr, {type: 'binary'})
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data:any[] = XLSX.utils.sheet_to_json(ws);
                let userDate:UserProfile[] = [];
                data.forEach((value, index) => {
                    // console.log(value);
                    userDate.push({
                        "id":index + 1,
                        "userId": value.患者id,
                        "profileId":value.病案号,
                        "name":value.患者姓名,
                        "birthday":formatOldBrithday(value.出生年月),
                        "gender":value.性别,
                        "ext":value.备注,
                    })
                })
                setTableData(userDate);
                messageApi.open({
                    type: 'success',
                    content: "读取excel成功！"
                })
            }
            reader.readAsBinaryString(files[0]);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: "上传文件失败！请选择合法的xls文件。"
            })
        }
    }

    return (
        <>
            {contextHolder}
            {editing !== undefined && (
                <Modal
                    title={'新增病人基础信息'}
                    width={'40vw'}
                    open={editing !== undefined}
                    onCancel={() => setEditing(undefined)}
                    onOk={() => {
                        // 校验各个必须的字段是否都有值
                        let checkRes = CheckAllBaseInfo(editing);
                        // 校验结果为空，那么可以直接提交
                        if (checkRes !== "") {
                            messageApi.open({
                                type: "error",
                                content:checkRes
                            })
                        } else {
                            try {
                                UploadOneBaseInfo([editing])
                                setEditing(undefined);
                                messageApi.success("提报成功")
                            } catch (e) {
                                messageApi.error("新增病人信息失败")
                            }
                        }
                    }}
                >
                    <BaseInfoEditor
                        initValue={editing}
                        onChange={v => {
                            setEditing(v);
                            // console.log("upload file output:", v);
                        }}
                    />
                </Modal>
            )}
            <Row justify="center" style={{margin:"5px 5px"}}>
                <Col >
                    新模板<input type="file" id="excel-file" onChange={importExcel} accept={".xls"}/>
                    老模板<input type="file" id="excel-file" onChange={importOldExcel} accept={".xls"} />
                    <Button onClick={() => {
                        setEditing({
                        name: '',
                        gender: "男",
                        beforeDiagnosis: "",
                        birthday: "",
                        bloodPressure: "",
                        ext: "无",
                        id: 0,
                        operator: "",
                        profileId: "",
                        pulse: 70,
                        strategyName: "",
                        userId: "",
                        weight: 50
                    })}}>新增病人基础信息</Button>
                    {selectedRows.length !== 0 && (
                        <>
                            <Button
                                type="text"
                                style={{
                                    color:"green"
                                }}
                                onClick={() => {
                                    batchUploadItem(selectedRows)
                                }
                                }
                            >
                                批量上传
                            </Button>
                            <Button
                                style={{
                                    marginLeft:"5px"
                                }}
                                type="text"
                                danger
                                onClick={()=> {
                                    batchDeleteItem(selectedRows)
                                }}
                            >
                                批量删除
                            </Button>
                        </>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        rowKey="id"
                        pagination={
                            {
                                showSizeChanger: true,
                                total: tableData.length,
                                showTotal: (totalNum, _) => `共 ${totalNum } 条`,
                                pageSizeOptions: [10, 20, 50, 100, 500, 1000]
                            }
                        }
                        style={{width: '100%'}}
                        dataSource={tableData}
                        columns={tableHeader}
                        rowSelection={{
                                type:'checkbox',
                                onChange: (selectedRowKeys: React.Key[], selectedRows: UserProfile[]) => {
                                    // console.log(selectedRows);
                                    setSelectedRows(selectedRows);
                            }
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}
